<template>
  <div class="app-container interviewInfo">
    <div class="titlebox">
      <el-button size="medium" type="text" @click="$router.go(-1)"
        >返回</el-button
      >
      <div class="title">面试详情</div>
    </div>
    <el-tabs v-model="tab">
      <el-tab-pane v-for="item in items"
        :key="item.key"
        :label = "item.label"
        :name="item.id">
      </el-tab-pane>
    </el-tabs>
    <rusumeInfo :iscompent="true" :resumeId="$route.query.resumeId" v-show="tab==0"></rusumeInfo>
    <el-collapse v-show="tab=='1'">
      <el-collapse-item class="recordBox" v-for="(record,i) in recordList" :key="i">
        <template slot="title">
          <div class="title-icon" :style="record.status==2?'margin-bottom:0':''">
            <strong style="font-size:18px">{{record.interviewName}}-{{record.staffName}} </strong>
            <el-tag :type="record.status=='2'?'success':'danger'" effect="dark" style="margin:0 10px;padding:0 10px" size="small">{{record.statusName}}</el-tag>
            {{`面试时间: ${record.interviewTime}`}}
          </div>
          <el-button type="primary" class="talentBtn" size="small" @click="$router.push({name:'TalentAssessment',query:{talentId:record.talentId,status:record.status,name: $route.query.name}})">人才评估</el-button>
        </template>
          <div v-show="record.status!=1" class="infoBox">
            <p style="font-size:16px"><strong>综合评价</strong></p>
            <p style="font-size:14px;margin-bottom:8px">总分：<strong style="font-size: 18px;color: #409EFF;">{{record.compositeScore}}分</strong></p>
            <p style="font-size:14px;margin-top:0">评语：{{record.compositeEvaluation}}</p>
            <p><strong style="font-size:16px">附件</strong></p>
            <div class="book" v-for="(file,i) in record.enclosureList" :key="i">
              <doc-file :file-name="file.name" :url="file.url"></doc-file>
            </div>
          </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import rusumeInfo from '../resumelibrary/entering';
import DocFile from '../../../components/DocFile';
import {interviewRecords} from '../../../api/recruitment_allocation';
export default {
  components: {
    DocFile,
    rusumeInfo
  },
  data() {
    return {
      tab: '1',
      items: [{label: '简历信息', id: '0', key: 0}, {label: '面试记录', id: '1', key: 1}],
      recordList: []
    };
  },
  mounted() {
    this.getRecords();
  },
  methods: {
    getRecords() {
      interviewRecords({resumeId: this.$route.query.resumeId, arrangeId: this.$route.query.arrangeId}).then((res) => {
        if (res._responseStatusCode == 0) {
          delete res._responseStatusCode;
          this.recordList = res;
          this.recordList.map((it) => {
            it.enclosureList = JSON.parse(it.enclosure);
          });
        }
      });
    }
  }
};
</script>

<style lang="scss">
  .interviewInfo{
    .recordBox {
      padding: 20px;
      margin-top:10px ;
      background-color: #fff;
      .el-collapse-item__header {
        position: relative;
        .talentBtn{
          position: absolute;
          right: 30px;
        }
        .title-icon {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 20px;
          &:before {
            display: inline-block;
            width: 8px;
            height: 18px;
            margin-top: 2px;
            background: #498df0;
            content: " ";
            border-radius: 5px;
            vertical-align: top;
            margin-right: 5px;
          }
        }
        .el-icon-arrow-right {
          margin-bottom: 2px;
        }
      }
      .infoBox{
        >p {
          margin: 10px 0;
          padding-left: 60px;
        }
        .book {
          padding:0 0 20px 100px;
          font-size: 14px;
        }
      }
    }
  }
</style>
